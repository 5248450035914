import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TokenService from "../../Services/TokenService";
import filterIcon from "../../assets/icons/filter.svg";
import search from "../../assets/icons/globe-blue.svg";
import thread from "../../assets/icons/thread.svg";
import animationImage from "../../assets/homePageimg.svg";
import MainSearch from "../../components/UI/MainSearch";
import { usePlaceholder } from "../../shared/hooks/usePlaceholder";
import MobileSeach from "../../components/UI/MobileSearch";
import CounsellingForm from "./CounsellingForm";
import ProgramSearch from "../../components/UI/ProgramSearch";

export default function Section1({ shouldScroll, setShouldScroll }) {
  const navigate = useNavigate();
  const token = TokenService.getLocalAccessToken();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(["Canada"]);
  const [isInputClicked, setIsInputClicked] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { placeholder } = usePlaceholder({
    stopChangingText: isInputClicked,
  });
  const handleHomeProgramSearch = (input) => {
    searchParams.set("searchQuery", searchQuery ?? "");
    searchParams.set("input", input ?? "");
    searchParams.set("selectedCountry", selectedCountry.join() ?? "");
    setSearchParams(searchParams);

    Boolean(token)
      ? navigate(`/user/program?${searchParams.toString()}`)
      : (window.location.href = `/programs?${searchParams.toString()}`);
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-between homePageView">
        {/* Hero begings */}
        <div className="pt-5 pt-lg-3">
          <div className="container mt-4 mt-md-5 pt-5">
            <div className="d-flex flex-column gap-5 flex-md-row mt-5">
              <div className="w-100 w-md-50 mt-5 mt-md-0">
                <div
                  className="row ps-3"
                  data-aos="zoom-in-up"
                  data-aos-duration="800"
                >
                  <div className="col-12">
                    <div className="hero-text">
                      Unlock Your Future, <br /> Study Abroad and Gain a{" "}
                      <span style={{ fontWeight: 700 }}>
                        Global Perspective
                      </span>
                    </div>
                  </div>
                  <div className="col-12 mt-5 pt-2 mt-md-2 position-relative">
                    <div className="d-none d-sm-block">
                      <ProgramSearch
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        handleHomeProgramSearch={handleHomeProgramSearch}
                        placeholder={placeholder}
                        isAuth={Boolean(token)}
                        isInputClicked={isInputClicked}
                        setIsInputClicked={setIsInputClicked}
                      />
                    </div>
                    <div className="d-block d-sm-none">
                      <MobileSeach
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        handleHomeProgramSearch={handleHomeProgramSearch}
                        isAuth={Boolean(token)}
                        isInputClicked={isInputClicked}
                        setIsInputClicked={setIsInputClicked}
                        placeholder={placeholder}
                      />
                    </div>

                    <div className="d-none d-md-block position-absolute end-0 bottom-0">
                      <img
                        className="position-absolute"
                        style={{ left: "-30px", right: "0px", bottom: "10px" }}
                        height="280px"
                        src={thread}
                        alt="thread"
                      />
                    </div>
                    {/* <div className="border rounded-pill p-1 shadow-sm order-1 bg-white">
                      <div className="position-relative d-flex">
                        <img src={CandaFlag} alt="flag" className="rounded-circle me-1" width={"36px"} />

                        <select 
                          className="border-0 form-select w-25 formControl main-page-search text1-3"
                          aria-label="country select"
                          defaultValue={"1"}
                        >
                          <option value="1">Canada</option>
                          <option value="2">Austria</option>
                          <option value="3">India</option>
                        </select>
                        <span className="border broder-end mx-2"></span>
                        <input
                          type="text"
                          className="form-control border-0 me-2 formControl main-page-search text1-3"
                          placeholder="Enter university or program"
                        />
                        <span className="top-50 end-0 position-absolute translate-middle-y">
                          <button className="btn btn-dark-blue rounded-pill px-3 text1-5">
                            <span className="me-2">
                              <i className="bi bi-search"></i>
                            </span>
                            Search
                          </button>
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-12 mt-5 ms-2">
                    <span
                      className=" text1-5 d-flex align-items-center justify-content-center justify-content-sm-start "
                      onClick={() => handleHomeProgramSearch("")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={filterIcon}
                        alt="filterIcon"
                        className="img-fluid me-1"
                      />{" "}
                      <p className="mb-0">Advance Search</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-100 w-md-50 mt-4 mt-md-5 d-flex justify-content-center justify-content-md-end">
                <CounsellingForm />
              </div>
            </div>
          </div>
        </div>
        {/* Hero Ends */}
        <div className="position-relative z-n1">
          {/* <video height='300px' width='100%' style={{ objectFit: 'cover' }} autoPlay loop muted>
            <source src='https://eduplanner-video-bucket.s3.ap-south-1.amazonaws.com/loop_white_premiere.mp4' type="video/mp4" />
          </video> */}
          <img
            src={animationImage}
            alt="animationImage"
            width="100%"
            style={{
              position: "relative",
              zIndex: -1,
              verticalAlign: "middle",
            }}
          />
          <button
            id="exploreButton"
            className="position-absolute d-flex align-items-center gap-1 border-0 bottom-0 end-0  bottom-0 end-0 rounded-pill bg-white p-3 m-4"
            onClick={() => setShouldScroll(true)}
          >
            <img src={search} alt="search" height="20px" />
            <strong className="m-0 mt-1 text1-3  text-dark1">
              {" "}
              Explore more!
            </strong>
          </button>
        </div>
      </div>
    </>
  );
}
